import GlobalConfig from '@services/globalConfig'
import request from '@services/request.service'

export interface IPageContentPayload {
  id?: string
  slug: string
  workingVersion: boolean
  channel: string
}
export interface IRefreshPagePayload {
  data?: any
}
export const retrievePageContentApi = (data: IPageContentPayload) => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/page`),
  data,
})
export const refreshPageContentApi = (data: IRefreshPagePayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/refresh-page`),
  data,
})

export class HomePageService {
  static async retrievePageContent(params: IPageContentPayload) {
    const res = await request(retrievePageContentApi(params))
    return res
  }
  static async refreshPageContent(params: IRefreshPagePayload) {
    const res = await request(refreshPageContentApi(params))
    return res
  }
}
